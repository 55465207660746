@media only screen and (max-width: 1199px) {
    #links-content h1 {
        font-size: 3.8rem;
    }
}

@media only screen and (max-width: 991px) {
    #links-content h1 {
        font-size: 3.6rem;
    }
}

@media only screen and (max-width: 767px) {
    #links-content h1 {
        font-size: 3.2rem;
    }

    #links-a a svg, #links-a a img {
        height: 5rem;
    }
}

@media only screen and (max-width: 549px) {
    #links-content h1 {
        font-size: 3rem;
    }

    #links-a a span {
        font-size: 1.2rem;
    }

    #links-a a svg, #links-a a img {
        height: 4.5rem;
    }
}

@media only screen and (max-width: 399px) {
    #links-content h1 {
        font-size: 2.6rem;
    }

    #links-a a svg, #links-a a img {
        height: 4rem;
    }
}
