#links-div {
    background-color: royalblue;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

#links-content {
    text-align: center;
    margin: 0 max(2vw, env(safe-area-inset-right)) 0 max(2vw, env(safe-area-inset-left));
    flex: 1;
}

#links-content h1 {
    color: midnightblue;
    font-size: 4rem;
    margin: 0 0 20px;
}

#links-a {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

#links-a a {
    display: flex;
    flex-direction: column;
    margin: 20px;
    text-decoration: none;
}

#links-a a span {
    color: white;
    font-size: 1.4rem;
    margin-top: 5px;
}

#links-a a svg, #links-a a img {
    color: white;
    height: 6rem;
    margin: auto;
}
