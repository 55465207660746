#shcoding-div {
    background-color: royalblue;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

#shcoding-content {
    text-align: center;
    width: 50%;
    margin: 0 auto;
}

#shcoding-content h1 {
    color: midnightblue;
    font-size: 4rem;
    margin: 0 0 20px;
}

#shcoding-content div {
    color: white;
    font-size: 1.8rem;
    margin: 0 0 20px;
}

#shcoding-apps-content {
    display: flex;
    flex: 1;
    text-align: center;
    font-size: 1.8rem;
    justify-content: center;
    flex-wrap: wrap;
}

#shcoding-apps-content div {
    display: flex;
    flex-direction: column;
    margin: 0 30px 20px;
}

#shcoding-apps-content a {
    color: white;
}

.upper-a {
    margin: 0 0 10px;
    text-decoration: none;
}

#shcoding-apps-content a span {
    text-decoration: none;
}

#shcoding-apps-content div a img {
    display: block;
    margin: 0 auto 10px;
    border-radius: 15%;
    max-width: 100%;
    height: auto;
}
