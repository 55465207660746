@media only screen and (max-width: 1600px) {
    #galerie-image-wrapper span {
        width: 22vw;
        height: 22vw;
    }

    #galerie-image-wrapper div {
        font-size: 3rem;
    }
}

@media only screen and (max-width: 1199px) {
    #galerie-image-wrapper span {
        width: 28vw;
        height: 28vw;
    }

    #galerie-image-wrapper div {
        font-size: 2.6rem;
    }
}

@media only screen and (max-width: 767px) {
    #galerie-content h1 {
        font-size: 3.6rem;
    }

    #galerie-image-wrapper span {
        width: 44vw;
        height: 44vw;
    }
}

@media only screen and (max-width: 549px) {
    #galerie-content h1 {
        font-size: 3.4rem;
    }

    #galerie-image-wrapper span {
        width: 42vw;
        height: 42vw;
    }

    #galerie-image-wrapper div {
        font-size: 1.8rem;
    }
}

@media only screen and (max-width: 319px) {
    #galerie-content h1 {
        font-size: 2.6rem;
    }

    #galerie-image-wrapper span {
        width: 80vw;
        height: 80vw;
    }

    #galerie-image-wrapper div {
        font-size: 2rem;
    }
}
