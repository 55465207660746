@media only screen and (max-width: 767px) {
    header {
        display: flex;
        flex-direction: column;
    }

    #hamburger-menu {
        display: block;
    }

    header nav {
        display: none;
        flex-direction: column;
    }

    #header-nav.change {
        display: flex;
    }
}
