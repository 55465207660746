footer {
    text-align: center;
    background-color: #b3ccff;
    margin-top: 40px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 1.3rem;
    color: black;
    width: 100%;
    z-index: 1;
}

#footer-content {
    margin-left: env(safe-area-inset-left);
    margin-right: env(safe-area-inset-right);
}

#footer-content span {
    margin-left: 15px;
    margin-right: 15px;
}

#footer-content a {
    color: #0a58ca;
}

#footer-content a:last-child {
    margin-left: 15px;
}

#footer-content a:link, #footer-content a:active {
    text-decoration: none;
}

#footer-content a svg {
    margin-left: 5px;
}
