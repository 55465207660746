#galerie-item-div {
    background-color: royalblue;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

#galerie-item-content {
    text-align: center;
    margin: 0 max(2vw, env(safe-area-inset-right)) 0 max(2vw, env(safe-area-inset-left));
    flex: 1;
}

#galerie-item-content h1 {
    color: midnightblue;
    font-size: 4rem;
    margin: 0 0 20px;
}

#galerie-item-images {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

#galerie-item-image-wrapper {
    position: relative;
    display: flex;
    cursor: pointer;
    margin: 5px;
}

#galerie-item-image-wrapper span {
    width: 18vw;
    height: 18vw;
}

#galerie-item-image-wrapper span img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

#galerie-item-image-wrapper div {
    position: absolute;
    bottom: 0;
    width: calc(100% - 20px);
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.6);
    color: white;
    font-size: 1.2rem;
    font-family: "Times New Roman", serif;
    padding: 10px;
}

#ReactSimpleImageViewer span {
    margin-left: env(safe-area-inset-left);
    margin-right: env(safe-area-inset-right);
}
